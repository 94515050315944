/// <reference types="typescript" />

interface IParticipant {
	place: string;
	zip: number;
	lat: number;
	lng: number;
	count: number;
	position: L.LatLng | null;
}

enum Zip {
	Liberec = 46001,
	Jablonec = 46601
}

class MapConfiguration {
	private zoomMin: number;
	private zoomDefault: number;
	private zoomMax: number;
	private centerCrLat: number;
	private centerCrLng: number;

	constructor() {
		if (window.matchMedia('(max-width: 767px)').matches) {
			this.zoomMin = 6;
			this.zoomDefault = 7;
			this.zoomMax = 10;
			this.centerCrLat = 50.1; //49.791595746544274;
			this.centerCrLng = 15.46392622187498;
		} else if (window.matchMedia('(max-width: 991px)').matches) {
			this.zoomMin = 6;
			this.zoomDefault = 7;
			this.zoomMax = 10;
			this.centerCrLat = 50.1; //49.791595746544274;
			this.centerCrLng = 15.46392622187498;
		} else if (window.matchMedia('(max-width: 1199px)').matches) {
			this.zoomMin = 7;
			this.zoomDefault = 8;
			this.zoomMax = 11;
			this.centerCrLat = 49.9; //49.791595746544274
			this.centerCrLng = 15.46392622187498;
		} else {
			this.zoomMin = 7;
			this.zoomDefault = 8;
			this.zoomMax = 11;
			this.centerCrLat = 49.9; //49.791595746544274
			this.centerCrLng = 15.46392622187498;
		}
	}

	getZoomMin(): number {
		return this.zoomMin;
	}

	getZoomDefault(): number {
		return this.zoomDefault;
	}

	getZoomMax(): number {
		return this.zoomMax;
	}

	getCenterCrLat(): number {
		return this.centerCrLat;
	}

	getCenterCrLng(): number {
		return this.centerCrLng;
	}
}

class CustomerMap {
	private readonly mapId = 'customer-map';
	private readonly mapParticipantId = 'map-participants';

	private readonly mapConfiguration: MapConfiguration;
	private readonly map: L.Map;

	public constructor() {
		this.mapConfiguration = new MapConfiguration();

		this.map = L.map(
			this.mapId,
			{
				center: [
					this.mapConfiguration.getCenterCrLat(),
					this.mapConfiguration.getCenterCrLng()
				],
				zoom: this.mapConfiguration.getZoomDefault(),
				minZoom: this.mapConfiguration.getZoomMin(),
				maxZoom: this.mapConfiguration.getZoomMax(),
				scrollWheelZoom: false,
			}
		);

		this.init();
	}

	private init() {
		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		}).addTo(this.map);

		const mapParticipantsEl = document.getElementById(this.mapParticipantId);

		const mapParticipants: IParticipant[] = JSON.parse(mapParticipantsEl.textContent || mapParticipantsEl.innerHTML);

		let maxParticipants = -Infinity;

		for (let key in mapParticipants) {
			mapParticipants[key].position = new L.LatLng(mapParticipants[key].lat, mapParticipants[key].lng);

			if (
				mapParticipants[key].count > maxParticipants
				&& mapParticipants[key].zip !== Zip.Liberec
				&& mapParticipants[key].zip !== Zip.Jablonec
			) {
				maxParticipants = mapParticipants[key].count;
			}
		}

		let marker: L.Marker[] = [];
		let circle: L.Circle[] = [];
		const maxRadius = 10000; // Nove bude 15000 jen Liberec, 12000 Jablonec
		const minRadius = maxRadius / 10; // default 5

		mapParticipants.map((data: IParticipant, i: number) => {
			marker[i] = L.marker(data.position, {
				title: data.count.toString()
			});
			marker[i].addTo(this.map);

			const divEl = document.createElement('div');

			const pEl = document.createElement('p');
			pEl.style.textAlign = 'left';

			const strongEl = document.createElement('strong');
			strongEl.innerHTML = data.place;

			const tableEl = document.createElement('table');
			const trEl = document.createElement('tr');
			const td1El = document.createElement('td');
			td1El.style.textAlign = 'right';
			td1El.innerHTML = 'účast na kurzech LŠF: ';

			const td2El = document.createElement('td');
			td2El.style.textAlign = 'right';
			td2El.style.paddingLeft = '1em';

			const strongTd2El = document.createElement('strong');
			strongTd2El.innerHTML = data.count.toString();

			divEl.appendChild(pEl);
			pEl.appendChild(strongEl);

			divEl.appendChild(tableEl);
			tableEl.appendChild(trEl);
			trEl.appendChild(td1El);
			trEl.appendChild(td2El);
			td2El.appendChild(strongTd2El);

			let radius = (data.count / maxParticipants) * maxRadius;

			if (data.zip === Zip.Liberec) {
				radius = 15000;
			} else if (data.zip === Zip.Jablonec) {
				radius = 12000;
			} else if (radius < minRadius) {
				radius = minRadius;
			}

			circle[i] = L.circle(data.position, {radius: radius});
			circle[i].addTo(this.map);

			marker[i].bindPopup(divEl, {
				autoPan: false
			});
			circle[i].on('click', () => {
				marker[i].togglePopup();
			});
		});
	}
}

$(document).ready(() => {
	const map = new CustomerMap();
});
